import React, { Component } from "react";
import { Row, Alert, Form, message, Tooltip, Checkbox } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import "./index.scss";
import { ReactComponent as Iconi } from "../../../../assets/images/icons/TooltipIcon.svg";
// import { ReactComponent as Iconi } from "../../../../../assets/images/icons/TooltipIcon.svg";
import { Link } from "react-router-dom";
import { currency } from "../../../../Helpers/countryList";
class AdvanceDetails extends Component {
  state = {
    editable: false,
    kycdone: false,
    ownername:
      this.props.kycdata && this.props.kycdata.name
        ? this.props.kycdata.name.toLowerCase()
        : "Not Available",
    orgname:
      this.props.kycdata && this.props.kycdata.org_name
        ? this.props.kycdata.org_name.toLowerCase()
        : "Not Available",

    registeraddress:
      this.props.kycdata && this.props.kycdata.address
        ? this.props.kycdata.address.line1
        : "Not Available",

    registeraddresstwo:
      this.props.kycdata && this.props.kycdata.address
        ? this.props.kycdata.address.line2
        : "Not Available",

    state:
      this.props.kycdata &&
      this.props.kycdata.address &&
      this.props.kycdata.address.state
        ? this.props.kycdata.address.state.toLowerCase()
        : "Not Available",

    city:
      this.props.kycdata &&
      this.props.kycdata.address &&
      this.props.kycdata.address.city
        ? this.props.kycdata.address.city.toLowerCase()
        : "Not Available",
    pincode:
      this.props.kycdata && this.props.kycdata.address
        ? this.props.kycdata.address.pincode
        : "Not Available",
  };

  handleChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  handleEditabledata() {
    this.setState({
      editable: !this.state.editable,
    });
  }

  canceltoggleEdit = () => {
    this.setState({
      editable: !this.state.editable,
      ownername: this.props.kycdata.name,
      orgname: this.props.kycdata.org_name,
      registeraddress: this.props.kycdata.address.line1,
      registeraddresstwo: this.props.kycdata.address.line1,
      state: this.props.kycdata.address.state,
      city: this.props.kycdata.address.city,
      pincode: this.props.kycdata.address.pincode,
    });
  };
  currencyText = (code) => {
    let items = currency.filter((obj) => obj.code == code);
    let item = items[0];
    let c = "";
    if (item.symbol) {
      c = "(" + item.symbol + ")";
    }
    return (
      <span>
        {item.code}{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: c,
          }}
        ></span>
      </span>
    );
  };
  saveChange() {
    if (
      this.props.kycdata.kyc_type === "Individual" &&
      this.state.ownername.length === 0
    ) {
      message.error("Please Enter  Name");
      return;
    }

    if (this.state.registeraddress.length === 0) {
      message.error("Please Enter address1");
      return;
    }

    if (this.state.state.length === 0) {
      message.error("Please Enter state");
      return;
    }
    if (this.state.city.length === 0) {
      message.error("Please Enter city");
      return;
    }
    if (this.state.pincode.length === 0) {
      message.error("Please Enter pincode");
      return;
    }
    if (
      this.props.kycdata.kyc_type === "Organisation" &&
      this.state.orgname.length === 0
    ) {
      message.error("Please  Enter OrgName");
      return;
    }

    this.setState(
      {
        loading: true,
        editable: !this.state.editable,
      },
      () => {
        message.loading("Saving", 0);
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/user/save_user_organisation_details",
          method: "post",
          data: {
            kycdata: {
              name:
                this.props.kycdata.kyc_type === "Individual"
                  ? this.state.ownername
                  : this.state.orgname,

              kyc_type: this.props.kycdata.kyc_type,
              address: {
                line1: this.state.registeraddress,
                line2: this.state.registeraddresstwo,
                state: this.state.state,
                city: this.state.city,
                pincode: this.state.pincode,
              },
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.props.fetchKycData();
              message.success("Details saved successfully.");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  }

  kycEditRequest() {
    if (this.state.kycdone === true || this.props.user.kyc_edit_request === 1) {
    } else {
      axios({
        url: process.env.REACT_APP_API_URL + "/user/edit_kyc_details",
        method: "post",
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            message.success(res.data.msg);
            this.setState({ kycdone: true });
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  }

  render() {
    let selectAccountType = {
      current: "Current Account",
      saving: "Saving Account",
      business: "Business Account",
      xme: "XME Account",
    };
    if (!this.state.loading && this.state.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }
    const { kycdata } = this.props;

    // const countryIfc =
    //   kycdata.kyc_country === "indian" ? kycdata.bank.ifsc : null;

    // const bankswift =
    //   kycdata.kyc_country === "non_indian" ? kycdata.bank.swift : null;

    const kycindiapan =
      kycdata.kyc_country === "indian" ? kycdata.pan_number : null;
    const kycindiadhar =
      kycdata.kyc_country === "indian" ? kycdata.aadhar_number : null;

    const kycindiagst =
      kycdata.kyc_country === "indian"
        ? kycdata.kyc_type === "Individual"
          ? null
          : kycdata.org_gst
        : kycdata.kyc_type === "Individual"
        ? null
        : kycdata.org_cin;

    const kycauthidnum =
      kycdata.kyc_country === "non_indian" ? kycdata.org_authidnum : null;

    const kycid_num =
      kycdata.kyc_country === "non_indian" ||
      kycdata.kyc_country === "Individual"
        ? kycdata.id_num
        : null;

    const kycidnum =
      kycdata.kyc_country === "non_indian" ? kycdata.org_idnum : null;

    var bankDataBlur = false;
    if (kycdata.bank === undefined) {
      bankDataBlur = true;
    } else {
      bankDataBlur =
        // countryIfc === undefined ||
        kycdata.bank.accno === undefined ||
        kycdata.bank.beneficiary_name === undefined;
      // bankswift === undefined;
    }

    const kycDataBlur =
      // kycindiapan === undefined ||
      // kycindiadhar === undefined ||
      kycdata.phone === undefined ||
      // kycindiagst === undefined ||
      (kycindiagst &&
        kycindiadhar &&
        kycindiapan &&
        kycid_num &&
        kycauthidnum &&
        kycidnum === undefined);

    return (
      <div className="advanceDetailContainer">
        {kycdata.kyc_country === "indian" ? (
          kycdata.kyc_type === "Individual" ? (
            <div>
              <div className="orgDetails">
                <div className="org-data">
                  <div className="fl">
                    <p style={{ color: "#212121" }}>Organisation Details </p>
                    <div className="right-gstdata-org-id">
                      {this.props.user &&
                      this.props.user.organisation &&
                      this.props.user.organisation.org_gstenabled === 1 ? (
                        <div className="right-org-data">
                          <span className="gst-enabled-gst">
                            <Checkbox
                              style={{ marginRight: 5 }}
                              checked={
                                this.props.user.organisation.org_gstenabled ===
                                1
                              }
                            />
                          </span>
                          <span className="gst-enabled-txt">GST Enabled</span>
                          <span className="border-line">|</span>
                        </div>
                      ) : null}

                      <p
                        style={{
                          color: "#898989",
                          fontSize: "12px",
                        }}
                      >
                        Organisation ID :
                        <span className="org-vfid">
                          {this.props.user.organisation.org_vfid}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="orgbasicupperdetail">
                  <div className="field">
                    <p className="fieldLabel "> Owner Name</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "ownername",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.ownername}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.ownername}</p>
                    )}
                  </div>
                  <div className="field1">
                    <p className="fieldLabel">Registered with vFulfill As</p>
                    <p className="fieldvalue">
                      {kycdata.kyc_type ? kycdata.kyc_type : "dkhasjdhak"}
                    </p>
                  </div>
                </div>

                <div className="orgbasicadressMiddledetail">
                  <div className="field">
                    <p className="fieldLabel ">Registered Address Line 1</p>

                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange("registeraddress", e.target.value)
                          }
                          value={this.state.registeraddress}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.registeraddress}</p>
                    )}
                  </div>
                  <div className="field1">
                    <p className="fieldLabel ">Registered Address Line 2</p>

                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "registeraddresstwo",
                              e.target.value
                            )
                          }
                          value={this.state.registeraddresstwo}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">
                        {this.state.registeraddresstwo}
                      </p>
                    )}
                  </div>
                </div>

                <div className="orgbasicdetailfooter">
                  <div className="field2">
                    <p className="fieldLabel">Country</p>
                    <p className="fieldvalue">
                      {kycdata.kyc_country === "non_indian"
                        ? "Non India"
                        : "India"}
                    </p>
                  </div>

                  <div className="field3">
                    <p className="fieldLabel ">State</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "state",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.state}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.state}</p>
                    )}
                  </div>

                  <div className="field4">
                    <p className="fieldLabel ">City</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "city",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.city}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.city}</p>
                    )}
                  </div>

                  <div className="field5">
                    <p className="fieldLabel ">Pin Code</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="number"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange("pincode", e.target.value)
                          }
                          value={this.state.pincode}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.pincode}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="KYCDetails">
                <div className="kyc-data">
                  <p style={{ color: "#212121", marginTop: "10px" }}>
                    KYC Details
                  </p>
                  <div>
                    {kycdata.pan_number ? (
                      <Tooltip
                        title={
                          this.state.kycdone === true ||
                          this.props.user.kyc_edit_request === 1
                            ? "You have already applied for KYC Change, you can request it again once the previous request has been fulfilled."
                            : null
                        }
                      >
                        <button
                          className={
                            this.state.kycdone === true ||
                            this.props.user.kyc_edit_request === 1
                              ? "disbalebtn"
                              : "clickable"
                          }
                          onClick={() => this.kycEditRequest()}
                        >
                          Request KYC Data Change
                        </button>
                      </Tooltip>
                    ) : kycDataBlur ? (
                      <div
                        className="kyc-blur-container"
                        // style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                      >
                        <Link
                          to={`/in/${this.props.auth.store.id}/onboarding#profile`}
                        >
                          <button
                            className="clickable"
                            style={{ height: "32px" }}
                          >
                            Add KYC Details
                          </button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="kyc-container">
                  {/* <div className="kycmiddledDetail">
                    <div className="field">
                      <p className="fieldLabel">Support Phone Number</p>
                      <p className="fieldvalue">
                        {!!kycdata.support_phone
                          ? kycdata.support_phone
                          : "Not Available"}
                      </p>
                    </div>

                    <div className="field1">
                      <p className="fieldLabel">Support Email</p>
                      <p className="fieldvalue">
                        {!!kycdata.support_email
                          ? kycdata.support_email
                          : "Not Available"}
                      </p>
                    </div>
                  </div> */}

                  <div className="KYCDetailsBasicdetail">
                    <div className="field">
                      <p className="fieldLabel">PAN Card Number</p>
                      <p className="fieldvalue">
                        {kycdata && kycdata.pan_number
                          ? kycdata.pan_number
                          : null}
                      </p>
                    </div>

                    {kycdata &&
                    kycdata.aadhar_number &&
                    !!kycdata.aadhar_number ? (
                      <div className="field1">
                        <p className="fieldLabel">Aadhar Card Number</p>
                        <p className="fieldvalue">
                          {kycdata &&
                          kycdata.aadhar_number &&
                          !!kycdata.aadhar_number
                            ? kycdata.aadhar_number
                            : null}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="orgDetails">
                <div className="org-data">
                  <div className="fl">
                    <p style={{ color: "#212121" }}>Organisation Details </p>
                    <div className="right-gstdata-org-id">
                      {this.props.user &&
                      this.props.user.organisation &&
                      this.props.user.organisation.org_gstenabled === 1 ? (
                        <div className="right-org-data">
                          <span className="gst-enabled-gst">
                            <Checkbox
                              style={{ marginRight: 5 }}
                              checked={
                                this.props.user.organisation.org_gstenabled ===
                                1
                              }
                            />
                          </span>
                          <span className="gst-enabled-txt">GST Enabled</span>
                          <span className="border-line">|</span>
                        </div>
                      ) : null}

                      <p
                        style={{
                          color: "#898989",
                          fontSize: "12px",
                        }}
                      >
                        Organisation ID :
                        <span className="org-vfid">
                          {this.props.user.organisation.org_vfid}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="orgbasicupperdetail">
                  <div className="field">
                    <p className="fieldLabel ">Organisation/ Business Name</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "orgname",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.orgname}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.orgname}</p>
                    )}
                  </div>
                </div>

                <div className="orgbasicupperdetail">
                  <div className="field">
                    <p className="fieldLabel">Registered with vFulfill As</p>
                    <p className="fieldvalue">{kycdata.kyc_type}</p>
                  </div>
                  <div className="field1">
                    <p className="fieldLabel">Country of Registration</p>
                    <p className="fieldvalue">
                      {kycdata.kyc_country === "non_indian"
                        ? "Non India"
                        : "India"}
                    </p>
                  </div>
                </div>

                <div className="orgbasicadressMiddledetail">
                  <div className="field">
                    <p className="fieldLabel ">Registered Address Line 1</p>

                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange("registeraddress", e.target.value)
                          }
                          value={this.state.registeraddress}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.registeraddress}</p>
                    )}
                  </div>
                  <div className="field1">
                    <p className="fieldLabel ">Registered Address Line 2</p>

                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "registeraddresstwo",
                              e.target.value
                            )
                          }
                          value={this.state.registeraddresstwo}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">
                        {this.state.registeraddresstwo}
                      </p>
                    )}
                  </div>
                </div>

                <div className="orgbasicdetailfooter">
                  <div className="field2">
                    <p className="fieldLabel">Country</p>
                    <p className="fieldvalue">
                      {kycdata.kyc_country === "non_indian"
                        ? "Non India"
                        : "India"}
                    </p>
                  </div>

                  <div className="field3">
                    <p className="fieldLabel ">State</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "state",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.state}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.state}</p>
                    )}
                  </div>

                  <div className="field4">
                    <p className="fieldLabel ">City</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="text"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange(
                              "city",
                              e.target.value.replace(/[^A-Za-z]/gi, " ")
                            )
                          }
                          value={this.state.city}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.city}</p>
                    )}
                  </div>

                  <div className="field5">
                    <p className="fieldLabel ">Pin Code</p>
                    {this.state.editable ? (
                      <p>
                        <input
                          type="number"
                          className="orgination-data-edit"
                          onChange={(e) =>
                            this.handleChange("pincode", e.target.value)
                          }
                          value={this.state.pincode}
                        />
                      </p>
                    ) : (
                      <p className="fieldvalue">{this.state.pincode}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="KYCDetails">
                <div className="kyc-data">
                  <p style={{ color: "#212121", marginTop: "10px" }}>
                    KYC Details
                  </p>
                  <div>
                    {kycdata.org_authpan ? (
                      <Tooltip
                        title={
                          this.state.kycdone === true ||
                          (this.props.user &&
                            this.props.user.kyc_edit_request === 1)
                            ? "You have already applied for KYC Change, you can request it again once the previous request has been fulfilled."
                            : null
                        }
                      >
                        <button
                          className={
                            this.state.kycdone === true ||
                            this.props.user.kyc_edit_request === 1
                              ? "disbalebtn"
                              : "clickable"
                          }
                          onClick={() => this.kycEditRequest()}
                        >
                          Request KYC Data Change
                        </button>
                      </Tooltip>
                    ) : kycDataBlur ? (
                      <div className="kyc-blur-container">
                        <Link
                          to={`/in/${this.props.auth.store.id}/onboarding#profile`}
                        >
                          <button
                            className="clickable"
                            style={{ height: "32px" }}
                          >
                            Add KYC Details
                          </button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="kyc-container">
                  <div className="KYCDetailsBasicdetail">
                    <div className="field">
                      <p className="fieldLabel">
                        Authorised Signatory’s PAN Card Number
                      </p>
                      <p className="fieldvalue">{kycdata.org_authpan}</p>
                    </div>

                    <div className="field1">
                      <p className="fieldLabel">GSTIN</p>
                      <p className="fieldvalue">{kycdata.org_gst}</p>
                    </div>
                  </div>

                  {/* <div className="kycmiddledDetail">
                    <div className="field">
                      <p className="fieldLabel">Support Phone Number</p>
                      <p className="fieldvalue">
                        {!!kycdata.support_phone
                          ? kycdata.support_phone
                          : "Not Available"}
                      </p>
                    </div>

                    <div className="field1">
                      <p className="fieldLabel">Support Email</p>
                      <p className="fieldvalue">
                        {!!kycdata.support_email
                          ? kycdata.support_email
                          : "Not Available"}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )
        ) : kycdata.kyc_type === "Individual" ? (
          <div>
            <div className="orgDetails">
              <div className="org-data">
                <div className="fl">
                  <p style={{ color: "#212121" }}>Organisation Details </p>
                  <div className="right-gstdata-org-id">
                    {this.props.user &&
                    this.props.user.organisation &&
                    this.props.user.organisation.org_gstenabled === 1 ? (
                      <div className="right-org-data">
                        <span className="gst-enabled-gst">
                          <Checkbox
                            style={{ marginRight: 5 }}
                            checked={
                              this.props.user.organisation.org_gstenabled === 1
                            }
                          />
                        </span>
                        <span className="gst-enabled-txt">GST Enabled</span>
                        <span className="border-line">|</span>
                      </div>
                    ) : null}

                    <p
                      style={{
                        color: "#898989",
                        fontSize: "12px",
                      }}
                    >
                      Organisation ID :
                      <span className="org-vfid">
                        {this.props.user &&
                          this.props.user.organisation &&
                          this.props.user.organisation.org_vfid}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="orgbasicupperdetail">
                <div className="field">
                  <p className="fieldLabel ">Owner Name</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "ownername",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.ownername}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.ownername}</p>
                  )}
                </div>
                <div className="field1">
                  <p className="fieldLabel">Registered with vFulfill As</p>
                  <p className="fieldvalue">{kycdata.kyc_type}</p>
                </div>
              </div>

              <div className="orgbasicadressMiddledetail">
                <div className="field">
                  <p className="fieldLabel ">Registered Address Line 1</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange("registeraddress", e.target.value)
                        }
                        value={this.state.registeraddress}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.registeraddress}</p>
                  )}
                </div>
                <div className="field1">
                  <p className="fieldLabel ">Registered Address Line 2</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "registeraddresstwo",
                            e.target.value
                          )
                        }
                        value={this.state.registeraddresstwo}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">
                      {this.state.registeraddresstwo}
                    </p>
                  )}
                </div>
              </div>

              <div className="orgbasicdetailfooter">
                <div className="field2">
                  <p className="fieldLabel">Registered in Country</p>
                  <p className="fieldvalue">
                    {" "}
                    {kycdata.kyc_country === "non_indian"
                      ? "Non India"
                      : "India"}
                  </p>
                </div>

                <div className="field3">
                  <p className="fieldLabel ">State</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "state",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.state}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.state}</p>
                  )}
                </div>

                <div className="field4">
                  <p className="fieldLabel ">City</p>

                  {this.state.editable ? (
                    <p>
                      <input
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "city",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.city}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.city}</p>
                  )}
                </div>

                <div className="field5">
                  <p className="fieldLabel ">Pin Code</p>

                  {this.state.editable ? (
                    <p>
                      <input
                        type="number"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange("pincode", e.target.value)
                        }
                        value={this.state.pincode}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.pincode}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="KYCDetails">
              <div className="kyc-data">
                <p style={{ color: "#212121", marginTop: "10px" }}>
                  KYC Details
                </p>
                <div>
                  {kycdata.id_num ? (
                    <Tooltip
                      title={
                        this.state.kycdone === true ||
                        (this.props.user &&
                          this.props.user.kyc_edit_request === 1)
                          ? "You have already applied for KYC Change, you can request it again once the previous request has been fulfilled."
                          : null
                      }
                    >
                      <button
                        className={
                          this.state.kycdone === true ||
                          (this.props.user &&
                            this.props.user.kyc_edit_request === 1)
                            ? "disbalebtn"
                            : "clickable"
                        }
                        onClick={() => this.kycEditRequest()}
                      >
                        Request KYC Data Change
                      </button>
                    </Tooltip>
                  ) : kycDataBlur ? (
                    <div
                      className="kyc-blur-container"
                      // style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    >
                      <Link
                        to={`/in/${this.props.auth.store.id}/onboarding#profile`}
                      >
                        <button
                          className={
                            this.state.kycdone === true ||
                            this.props.user.kyc_edit_request === 1
                              ? "disbalebtn"
                              : "clickable"
                          }
                          style={{ height: "32px" }}
                        >
                          Add KYC Details
                        </button>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="kyc-container">
                {/* <div className="kycmiddledDetail">
                  <div className="field">
                    <p className="fieldLabel">Support Phone Number</p>
                    <p className="fieldvalue">
                      {!!kycdata.support_phone
                        ? kycdata.support_phone
                        : "Not Available"}
                    </p>
                  </div>

                  <div className="field1">
                    <p className="fieldLabel">Support Email</p>
                    <p className="fieldvalue">
                      {!!kycdata.support_email
                        ? kycdata.support_email
                        : "Not Available"}
                    </p>
                  </div>
                </div> */}

                <div className="kycmiddledDetail">
                  <div className="field">
                    <p className="fieldLabel">Identification Number</p>
                    <p
                      className="fieldvalue"
                      style={{
                        width: "50%",
                      }}
                    >
                      {kycdata.id_num}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="orgDetails">
              <div className="org-data">
                <div className="fl">
                  <p style={{ color: "#212121" }}>Organisation Details </p>
                  <div className="right-gstdata-org-id">
                    {this.props.user &&
                    this.props.user.organisation &&
                    this.props.user.organisation.org_gstenabled === 1 ? (
                      <div className="right-org-data">
                        <span className="gst-enabled-gst">
                          <Checkbox
                            style={{ marginRight: 5 }}
                            checked={
                              this.props.user.organisation.org_gstenabled === 1
                            }
                          />
                        </span>
                        <span className="gst-enabled-txt">GST Enabled</span>
                        <span className="border-line">|</span>
                      </div>
                    ) : null}

                    <p
                      style={{
                        color: "#898989",
                        fontSize: "12px",
                      }}
                    >
                      Organisation ID :
                      <span className="org-vfid">
                        {this.props.user.organisation.org_vfid}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="orgbasicupperdetail">
                <div className="field">
                  <p className="fieldLabel ">Organisation/ Business Name</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "orgname",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.orgname}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.orgname}</p>
                  )}
                </div>
              </div>

              <div className="orgbasicupperdetail">
                <div className="field">
                  <p className="fieldLabel">Registered with vFulfill As</p>
                  <p className="fieldvalue">{kycdata.kyc_type}</p>
                </div>
                <div className="field1">
                  <p className="fieldLabel">Country of Registration</p>
                  <p className="fieldvalue">
                    {kycdata.kyc_country === "non_indian"
                      ? "Non India"
                      : "India"}
                  </p>
                </div>
              </div>

              <div className="orgbasicadressMiddledetail">
                <div className="field">
                  <p className="fieldLabel ">Business Address Line 1</p>

                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange("registeraddress", e.target.value)
                        }
                        value={this.state.registeraddress}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.registeraddress}</p>
                  )}
                </div>
                <div className="field1">
                  <p className="fieldLabel ">Business Address Line 2</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "registeraddresstwo",
                            e.target.value
                          )
                        }
                        value={this.state.registeraddresstwo}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">
                      {this.state.registeraddresstwo}
                    </p>
                  )}
                </div>
              </div>

              <div className="orgbasicdetailfooter">
                <div className="field2">
                  <p className="fieldLabel">Country</p>
                  <p className="fieldvalue">
                    {kycdata.kyc_country === "non_indian"
                      ? "Non India"
                      : "India"}
                  </p>
                </div>

                <div className="field3">
                  <p className="fieldLabel ">State</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "state",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.state}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.state}</p>
                  )}
                </div>

                <div className="field4">
                  <p className="fieldLabel ">City</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="text"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange(
                            "city",
                            e.target.value.replace(/[^A-Za-z]/gi, " ")
                          )
                        }
                        value={this.state.city}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.city}</p>
                  )}
                </div>

                <div className="field5">
                  <p className="fieldLabel ">Pin Code</p>
                  {this.state.editable ? (
                    <p>
                      <input
                        type="number"
                        className="orgination-data-edit"
                        onChange={(e) =>
                          this.handleChange("pincode", e.target.value)
                        }
                        value={this.state.pincode}
                      />
                    </p>
                  ) : (
                    <p className="fieldvalue">{this.state.pincode}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="KYCDetails">
              <div className="kyc-data">
                <p style={{ color: "#212121", marginTop: "10px" }}>
                  KYC Details
                </p>
                <div>
                  {kycdata.org_authidnum ? (
                    <Tooltip
                      title={
                        this.state.kycdone === true ||
                        (this.props.user &&
                          this.props.user.kyc_edit_request === 1)
                          ? "You have already applied for KYC Change, you can request it again once the previous request has been fulfilled."
                          : null
                      }
                    >
                      <button
                        className={
                          this.state.kycdone === true ||
                          (this.props.user &&
                            this.props.user.kyc_edit_request === 1)
                            ? "disbalebtn"
                            : "clickable"
                        }
                        onClick={() => this.kycEditRequest()}
                      >
                        Request KYC Data Change
                      </button>
                    </Tooltip>
                  ) : kycDataBlur ? (
                    <div className="kyc-blur-container">
                      <Link
                        to={`/in/${this.props.auth.store.id}/onboarding#profile`}
                      >
                        <button
                          className="clickable"
                          style={{ height: "32px" }}
                        >
                          Add KYC Details
                        </button>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="kyc-container">
                <div className="KYCDetailsBasicdetail">
                  <div className="field">
                    <p className="fieldLabel">
                      Authorised Signatory’s Identification Number
                    </p>
                    <p className="fieldvalue">
                      {kycdata.org_authidnum
                        ? kycdata.org_authidnum
                        : "Not Available"}
                    </p>
                  </div>

                  {!!kycdata.org_idnum ? (
                    <div className="field1">
                      <p className="fieldLabel">Business Identity Number</p>
                      <p className="fieldvalue">
                        {kycdata.org_idnum
                          ? kycdata.org_idnum
                          : "Not Available"}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="kycmiddledDetail">
                  {!!kycdata.org_idnum ? (
                    <div className="field1">
                      <p className="fieldLabel">CIN</p>
                      <p className="fieldvalue">
                        {kycdata.org_idnum
                          ? kycdata.org_idnum
                          : "Not Available"}
                      </p>
                    </div>
                  ) : null}
                </div>

                {/* <div className="kycmiddledDetail">
                  <div className="field">
                    <p className="fieldLabel">Support Phone Number</p>
                    <p className="fieldvalue">
                      {!!kycdata.support_phone
                        ? kycdata.support_phone
                        : "Not Available"}
                    </p>
                  </div>

                  <div className="field1">
                    <p className="fieldLabel">Support Email</p>
                    <p className="fieldvalue">
                      {!!kycdata.support_email
                        ? kycdata.support_email
                        : "Not Available"}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}

        <div className="bankingdetail">
          <div className="banking-data">
            <p style={{ color: "#212121", marginTop: "10px" }}>
              Banking Details
            </p>

            {bankDataBlur === true ? (
              <div className="bank-detail-blur-container">
                <Link to={`/in/${this.props.auth.store.id}/onboarding#profile`}>
                  <button className="clickable" style={{ height: "32px" }}>
                    Add Banking Details
                  </button>
                </Link>
              </div>
            ) : null}
          </div>

          <div className="bank-container">
            <div className="bankingBasicDetail">
              <div className="field">
                <p className="fieldLabel">
                  {kycdata && kycdata.kyc_country === "indian"
                    ? `Branch IFSC Code`
                    : "SWIFT Code"}{" "}
                </p>
                <p className="fieldvalue">
                  {this.props.kycdata &&
                  this.props.kycdata.kyc_country === "indian"
                    ? kycdata.bank && kycdata.bank.ifsc
                      ? kycdata.bank && kycdata.bank.ifsc
                      : "Not Available"
                    : kycdata.bank && kycdata.bank.swift
                    ? kycdata.bank.swift
                    : "Not Available"}
                </p>
              </div>
              <div className="field1">
                <p className="fieldLabel">Account Number</p>
                <p className="fieldvalue">
                  {kycdata.bank && kycdata.bank.accno
                    ? kycdata.bank.accno
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div className="bankingBasicDetail">
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field">
                  <p className="fieldLabel">Routing Number</p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.routing_number
                      ? kycdata.bank.routing_number
                      : "Not Available"}
                  </p>
                </div>
              )}
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field1">
                  <p className="fieldLabel">Account Type</p>
                  <p className="fieldvalue">
                    {kycdata.bank && kycdata.bank.account_type
                      ? selectAccountType[kycdata.bank.account_type]
                      : "Not Available"}
                  </p>
                </div>
              )}
            </div>
            <div className="bankingBasicDetail">
              <div
                className="field"
                // style={{ width: "50%", paddingRight: "8px", marginTop: "16px" }}
              >
                <p className="fieldLabel">Beneficiary Name</p>
                <p className="fieldvalue">
                  {kycdata.bank && kycdata.bank.beneficiary_name
                    ? kycdata.bank.beneficiary_name
                    : "Not Available"}
                </p>
              </div>

              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field1">
                  <p className="fieldLabel">
                    IBAN{" "}
                    <Tooltip
                      title={
                        <span style={{ position: "relative" }}>
                          International Bank Account Number
                        </span>
                      }
                    >
                      <span
                        className="icon-i-ontracking i-j"
                        style={{ position: "relative", top: "2px" }}
                      >
                        <Iconi />
                      </span>
                    </Tooltip>
                  </p>
                  <p className="fieldvalue">
                    {this.props.kycdata && kycdata.bank && kycdata.bank.iban
                      ? kycdata.bank.iban
                      : "Not Available"}
                  </p>
                </div>
              )}
            </div>
            <div className="bankingBasicDetail">
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field">
                  <p className="fieldLabel">BIC Code</p>
                  <p className="fieldvalue">
                    {this.props.kycdata && kycdata.bank && kycdata.bank.bic_code
                      ? kycdata.bank.bic_code
                      : "Not Available"}
                  </p>
                </div>
              )}
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field1">
                  <p className="fieldLabel">Intermediary BIC Code</p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.intermediary_bic_code
                      ? kycdata.bank.intermediary_bic_code
                      : "Not Available"}
                  </p>
                </div>
              )}
            </div>
            <div className="bankingBasicDetail">
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field">
                  <p className="fieldLabel">Beneficiary Address</p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.beneficiary_address
                      ? kycdata.bank.beneficiary_address
                      : "Not Available"}
                  </p>
                </div>
              )}

              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field1">
                  <p className="fieldLabel">Bank / Payment institution</p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.bank_paymentinstitution
                      ? kycdata.bank.bank_paymentinstitution
                      : "Not Available"}
                  </p>
                </div>
              )}
            </div>
            <div className="bankingBasicDetail">
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field">
                  <p className="fieldLabel">
                    Bank / Payment institution address
                  </p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.bank_paymentinstitution_address
                      ? kycdata.bank.bank_paymentinstitution_address
                      : "Not Available"}
                  </p>
                </div>
              )}
              {this.props.kycdata &&
              this.props.kycdata.kyc_country === "indian" ? null : (
                <div className="field1">
                  <p className="fieldLabel">Currency</p>
                  <p className="fieldvalue">
                    {this.props.kycdata &&
                    kycdata.bank &&
                    kycdata.bank.currency_code
                      ? this.currencyText(kycdata.bank.currency_code)
                      : "Not Available"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ auth: state.auth.auth });

export default Form.create()(connect(mapStateToProps)(AdvanceDetails));
